// Features
// Internal features
import * as asrAnnotations from './internalResources/asrAnnotations';
import * as features from './internalResources/featureFlag';
import * as accounts from './resources/accounts';
import * as analytics from './resources/analytics';
import * as audioCache from './resources/audioCache';
import * as balance from './resources/balance';
import * as chat from './resources/chat';
import * as config from './resources/config';
import * as conversation from './resources/conversation';
import * as deployments from './resources/deployments';
import * as disclaimer from './resources/disclaimer';
import * as flows from './resources/flows';
import * as functions from './resources/functions';
import * as handoff from './resources/handoff';
import * as knowledgeBase from './resources/knowledgeBase';
import * as language from './resources/language';
import * as payments from './resources/payments';
import * as phoneNumber from './resources/phoneNumber';
import * as projects from './resources/projects';
import * as pronunciations from './resources/pronunciations';
import * as questionnaire from './resources/questionnaire';
import * as resourceMetrics from './resources/resourceMetrics';
import * as sampleProjects from './resources/sampleProjects';
import * as secretsManager from './resources/secretsManager';
import * as smsCredentials from './resources/smsCredentials';
import * as smsTemplates from './resources/smsTemplates';
import * as speechRecognition from './resources/speechRecognition';
import * as stopKeywords from './resources/stopKeywords';
import * as telephony from './resources/telephony';
import * as topics from './resources/topics';
import * as userManagement from './resources/userManagement';
import * as variantManagement from './resources/variantManagement';
import * as voiceTuningSettings from './resources/voiceTuningSettings';

export * as types from './types';

export const api = {
  ...accounts,
  ...analytics,
  ...asrAnnotations,
  ...audioCache,
  ...balance,
  ...chat,
  ...config,
  ...conversation,
  ...features,
  ...flows,
  ...functions,
  ...handoff,
  ...knowledgeBase,
  ...language,
  ...payments,
  ...phoneNumber,
  ...projects,
  ...questionnaire,
  ...sampleProjects,
  ...secretsManager,
  ...smsCredentials,
  ...smsTemplates,
  ...telephony,
  ...topics,
  ...userManagement,
  ...voiceTuningSettings,
  ...deployments,
  ...pronunciations,
  ...disclaimer,
  ...resourceMetrics,
  ...stopKeywords,
  ...variantManagement,
  ...speechRecognition,
};

export default api;
